import React, { useState, useEffect, useContext, useRef } from "react";
import ContentComponent from "../../Content/Content";
import "./simplePay.scss";
import "../../../assets/v2/main.scss";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import SearchList from "../../Search/SearchList/SearchList";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";
import SimplePayForm from "./SimplePayDetails/SimplePayForm";
import { sassEndpoints } from "../../../constants/endpoints";
import { stringFormat } from "../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { buildPatchOperations } from "./SimplePayHelpers";
import emptyState from "../../../assets/images/empty-state.png";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";

const SimplePayContainer = () => {
  const { userSettings } = useContext(UserSettingsContext);
  const gateway = useContext(GatewayContext);
  const canCreateSimplePay =
    userSettings.gatewayUserPermissions["CREATE_SIMPLEPAY"];
  const canDeleteSimplePay =
    userSettings.gatewayUserPermissions["DELETE_SIMPLEPAY"];
  const [canEditSimplePay, setCanEditSimplePay] = useState(
    userSettings.gatewayUserPermissions["EDIT_SIMPLEPAY"],
  );

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);

  const methods = useForm();

  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const actionOpen = Boolean(actionAnchorEl);
  const [selectedPageForMenu, setSelectedPageForMenu] = useState(null);

  const [formKey, setFormKey] = useState("");
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isLoadingProcessors, setIsLoadingProcessors] = useState(true);
  const [isLoadingSmplPayPage, setIsLoadingSmplPayPage] = useState(true);

  const [simplePayList, setSimplePayList] = useState([]);
  const [processors, setProcessors] = useState([]);
  const [simplePayResponse, setSimplePayResponse] = useState(null);
  // eslint-disable-next-line
  const [loadingMore, setLoadingMore] = useState(false);
  // eslint-disable-next-line
  const [metadataMapping, setMetadataMapping] = useState({});

  const [resultsTotal, setResultsTotal] = useState(0);
  const [resultOffset, setResultOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const resultsPerPage = 10;

  const StyledToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "16px",
    },
  });

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    fetchProcessors();
    fetchMetadataMapping();
    loadSimplePayPages(true); // Initial load with reload = true

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (simplePayList.length > 0 && activeIndex === null) {
      setActiveIndex(0);
    }
  }, [simplePayList]);

  useEffect(() => {
    if (
      activeIndex !== null &&
      !isCreatingNew &&
      simplePayList.length > 0 &&
      simplePayList[activeIndex]
    ) {
      retrieveSimplePayPage();
    } else if (activeIndex === null) {
      setSimplePayResponse(null);
    }
  }, [activeIndex, simplePayList]);

  useEffect(() => {
    setCanEditSimplePay(
      userSettings.gatewayUserPermissions["EDIT_SIMPLEPAY"] || isCreatingNew,
    );
  }, [isCreatingNew]);

  const processMetadataMapping = (data) => {
    const mapping = {};
    data.forEach((preference) => {
      const preferenceName = preference.preferenceName;
      if (!mapping[preferenceName]) {
        mapping[preferenceName] = {};
      }
      preference.preferenceMetadata.forEach((meta) => {
        mapping[preferenceName][meta.metaDataName] = String(meta.metaDataId);
      });
    });
    return mapping;
  };

  const generateUrl = (token) => {
    return stringFormat(
      `${process.env.REACT_APP_IQPRO_PAY_URL}/simple?token={simplePayToken}`,
      [token],
    );
  };

  const fetchProcessors = () => {
    const url = stringFormat(sassEndpoints.processors.processor, [
      userSettings.gatewayId,
    ]);

    setIsLoadingProcessors(true);
    axios
      .get(url)
      .then((response) => {
        if (isMounted.current) {
          if (response.status === 200) {
            const processors = response.data.data;
            setProcessors(processors);
          } else {
            showAlertDialogError("Failed to fetch gateway processors.");
          }
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          showAlertDialogError(
            error?.response?.data?.statusDetails ||
              "Failed to fetch gateway processors.",
          );
        }
      })
      .finally(() => {
        setIsLoadingProcessors(false);
      });
  };

  const fetchMetadataMapping = () => {
    const url = stringFormat(sassEndpoints.simplePay.metadata, [
      userSettings.gatewayId,
    ]);
    axios
      .get(url)
      .then((response) => {
        if (isMounted.current) {
          if (response.status === 200) {
            const mapping = processMetadataMapping(response.data.data);
            setMetadataMapping(mapping);
          } else {
            showAlertDialogError("Failed to fetch metadata mapping.");
          }
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          showAlertDialogError(
            error?.response?.data?.statusDetails ||
              "Failed to fetch metadata mapping.",
          );
        }
      });
  };

  const loadSimplePayPages = (reload = false) => {
    const url = stringFormat(sassEndpoints.simplePay.search, [
      userSettings.gatewayId,
    ]);
    let payload = {};

    payload.limit = resultsPerPage;

    let offset = resultOffset;
    if (reload) {
      offset = 0;
      setResultOffset(0);
      setResultsTotal(0);
      setSimplePayList([]);
      setHasMoreData(true);
    }
    payload.offset = offset;

    setLoadingMore(true);

    return axios
      .post(url, payload)
      .then((resp) => {
        if (isMounted.current) {
          if (resp.status === 204 || resp.data.data.results.length === 0) {
            if (reload) {
              setSimplePayList([]);
              setResultsTotal(0);
              setActiveIndex(null);
            }
            setHasMoreData(false);
          } else {
            const newResults = resp.data.data.results;
            setSimplePayList((prevList) => {
              const uniqueResults = newResults.filter(
                (newItem) =>
                  !prevList.some(
                    (existingItem) =>
                      existingItem.simplePayId === newItem.simplePayId,
                  ),
              );
              return reload ? newResults : [...prevList, ...uniqueResults];
            });
            setResultsTotal(resp.data.data.rowCount);

            const totalItemsFetched = offset + newResults.length;
            setResultOffset(totalItemsFetched);

            if (totalItemsFetched >= resp.data.data.rowCount) {
              setHasMoreData(false);
            } else {
              setHasMoreData(true);
            }
          }
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          showAlertDialogError(
            error?.response?.data?.statusDetails ||
              "Failed to load Simple Pay pages.",
          );
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingMore(false);
        }
      });
  };

  const handleFetchData = () => {
    return loadSimplePayPages(false);
  };

  const handleClick = (e, page) => {
    e.stopPropagation();
    setSelectedPageForMenu(page);
    setActionAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setActionAnchorEl(null);
  };

  const handleCopy = (url) => {
    showSnackBar("Copied URL to clipboard!");
    navigator.clipboard.writeText(url);
    handleClose();
  };

  const handleView = () => {
    window.open(generateUrl(selectedPageForMenu.token), "_blank");
    handleClose();
  };

  const handleDelete = () => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: [
        `Once this Simple Pay page has been ${
          selectedPageForMenu.title === "New page" ? "cancelled" : "deleted"
        } it cannot be undone.`,
      ],
      actionButtons: [
        {
          text: `Yes, ${
            selectedPageForMenu.title === "New page" ? "Cancel" : "Delete"
          } Simple Pay Page!`,
          color: "secondary",
          onclick: () => deleteSimplePay(selectedPageForMenu),
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
    handleClose();
  };

  const deleteSimplePay = (page) => {
    const url = stringFormat(sassEndpoints.simplePay.simplePay, [
      userSettings.gatewayId,
      page.simplePayId,
    ]);
    setShowBackdrop(true);
    axios
      .delete(url)
      .then((resp) => {
        if (isMounted.current) {
          if (resp.status === 204) {
            showSnackBar("Simple Pay page deleted!");
            setSimplePayList((prevList) =>
              prevList.filter((item) => item.simplePayId !== page.simplePayId),
            );
            setResultsTotal((prevTotal) => prevTotal - 1);

            // Reset hasMoreData if necessary
            if (simplePayList.length - 1 < resultsTotal - 1) {
              setHasMoreData(true);
            }

            if (simplePayList.length === 1) {
              setActiveIndex(null);
              setSimplePayResponse(null);
            } else if (activeIndex >= simplePayList.length - 1) {
              setActiveIndex(simplePayList.length - 2);
            }
          } else {
            showAlertDialogError("Failed to delete Simple Pay page.");
          }
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          showAlertDialogError(
            error?.response?.data?.statusDetails ||
              "Failed to delete Simple Pay page.",
          );
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowBackdrop(false);
          setAlertDialogOpen(false);
        }
      });
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const onCreate = () => {
    if (!isCreatingNew) {
      setIsCreatingNew(true);
      setSimplePayResponse(null);
      setActiveIndex(null);
      setFormKey("-1");
      methods.reset();
    }
  };

  const stripBase64Prefix = (base64String) => {
    // Check if the base64String contains the prefix
    if (base64String && base64String.startsWith("data:image/png;base64,")) {
      return base64String.replace("data:image/png;base64,", "");
    }
    if (base64String && base64String.startsWith("data:image/jpeg;base64,")) {
      return base64String.replace("data:image/jpeg;base64,", "");
    }
    return base64String; // Return as is if no prefix
  };

  const onSave = async (formValues) => {
    const strippedLogo = stripBase64Prefix(formValues.logo);

    const simplePayBrandingFields = [
      {
        code: metadataMapping["SimplePay Branding"]["DISPLAY_BASYS_BRANDING"],
        value: "true",
      },
      {
        code: metadataMapping["SimplePay Branding"]["DISPLAY_BANNER"],
        value: formValues.display_banner ? "true" : "false",
      },
      {
        code: metadataMapping["SimplePay Branding"]["BANNER_TYPE_ID"],
        value: String(formValues.banner_type_id) || "2",
      },
      {
        code: metadataMapping["SimplePay Branding"]["BANNER_MESSAGE"],
        value: formValues.banner_message || "",
      },
      {
        code: metadataMapping["SimplePay Branding"]["LOGO_ALIGN"],
        value: formValues.logo_align || "",
      },
      {
        code: metadataMapping["SimplePay Branding"]["BACKGROUND_COLOR"],
        value: formValues.background_color || "#fff",
      },
      {
        code: metadataMapping["SimplePay Branding"]["PAYMENT_COLOR"],
        value: formValues.payment_color || "#d4effa",
      },
      {
        code: metadataMapping["SimplePay Branding"]["PRIMARY_COLOR"],
        value: formValues.primary_color || "#189ad0",
      },
    ];

    if (formValues.logo === "") {
      simplePayBrandingFields.push({
        code: metadataMapping["SimplePay Branding"]["LOGO"],
        value: formValues.logo || "",
      });
    }

    const requestBody = {
      name: formValues.header_name || "Untitled Page",
      simplePaySettings: [
        {
          preferenceType: "SimplePay Branding",
          fieldEntryValues: simplePayBrandingFields,
        },
        {
          preferenceType: "SimplePay Card Form Settings",
          fieldEntryValues: [
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "ENABLE_CARD"
              ],
              value: formValues.payment_type_selector.includes("card")
                ? "true"
                : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "ACCEPT_VISA"
              ],
              value: formValues.card.accepted_cards?.includes("visa")
                ? "true"
                : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "ACCEPT_MASTERCARD"
              ],
              value: formValues.card.accepted_cards?.includes("mastercard")
                ? "true"
                : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "ACCEPT_DISCOVER"
              ],
              value: formValues.card.accepted_cards?.includes("discover")
                ? "true"
                : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "ACCEPT_AMEX"
              ],
              value: formValues.card.accepted_cards?.includes("amex")
                ? "true"
                : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "INCLUDE_COMPANY_FIELD"
              ],
              value: formValues.card.include_company_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "REQUIRE_COMPANY_FIELD"
              ],
              value: formValues.card.require_company_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "COMPANY_FIELD_LABEL"
              ],
              value: formValues.card.company_field_label || "",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "INCLUDE_ORDER_ID_FIELD"
              ],
              value: formValues.card.include_order_id_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "REQUIRE_ORDER_ID_FIELD"
              ],
              value: formValues.card.require_order_id_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "ORDER_ID_FIELD_LABEL"
              ],
              value: formValues.card.order_id_field_label || "",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "INCLUDE_PO_NUMBER_FIELD"
              ],
              value: formValues.card.include_po_number_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "REQUIRE_PO_NUMBER_FIELD"
              ],
              value: formValues.card.require_po_number_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "PO_NUMBER_FIELD_LABEL"
              ],
              value: formValues.card.po_number_field_label || "",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "INCLUDE_ADDRESS_FIELDS"
              ],
              value: formValues.card.include_address_fields ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "REQUIRE_ADDRESS_FIELDS"
              ],
              value: formValues.card.require_address_fields ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "INCLUDE_EMAIL_FIELD"
              ],
              value: formValues.card.include_email_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "REQUIRE_EMAIL_FIELD"
              ],
              value: formValues.card.require_email_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "INCLUDE_PHONE_FIELD"
              ],
              value: formValues.card.include_phone_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "REQUIRE_PHONE_FIELD"
              ],
              value: formValues.card.require_phone_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "INCLUDE_NOTE_FIELD"
              ],
              value: formValues.card.include_note_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay Card Form Settings"][
                "ADDITIONAL_INFO_HEADER"
              ],
              value: formValues.card.additional_info_header || "",
            },
          ],
        },
        {
          preferenceType: "SimplePay ACH Form Settings",
          fieldEntryValues: [
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "ENABLE_ACH"
              ],
              value: formValues.payment_type_selector.includes("ach")
                ? "true"
                : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "INCLUDE_COMPANY_FIELD"
              ],
              value: formValues.ach.include_company_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "REQUIRE_COMPANY_FIELD"
              ],
              value: formValues.ach.require_company_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "COMPANY_FIELD_LABEL"
              ],
              value: formValues.ach.company_field_label || "",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "INCLUDE_ORDER_ID_FIELD"
              ],
              value: formValues.ach.include_order_id_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "REQUIRE_ORDER_ID_FIELD"
              ],
              value: formValues.ach.require_order_id_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "ORDER_ID_FIELD_LABEL"
              ],
              value: formValues.ach.order_id_field_label || "",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "INCLUDE_PO_NUMBER_FIELD"
              ],
              value: formValues.ach.include_po_number_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "REQUIRE_PO_NUMBER_FIELD"
              ],
              value: formValues.ach.require_po_number_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "PO_NUMBER_FIELD_LABEL"
              ],
              value: formValues.ach.po_number_field_label || "",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "INCLUDE_NOTE_FIELD"
              ],
              value: formValues.ach.include_note_field ? "true" : "false",
            },
            {
              code: metadataMapping["SimplePay ACH Form Settings"][
                "ADDITIONAL_INFO_HEADER"
              ],
              value: formValues.ach.additional_info_header || "",
            },
          ],
        },
      ],
      ...(formValues.logo !== "" && {
        image: {
          filename: "simplePayLogo",
          data: strippedLogo || "",
        },
      }),
    };

    if (isCreatingNew) {
      const url = stringFormat(sassEndpoints.simplePay.create, [
        userSettings.gatewayId,
      ]);

      setShowBackdrop(true);
      axios
        .post(url, requestBody)
        .then((response) => {
          if (isMounted.current) {
            if (response.status === 201) {
              showSnackBar("Success - Simple Pay Page Created");
              setIsCreatingNew(false);
              loadSimplePayPages(true);
            } else {
              showAlertDialogError("Failed to create Simple Pay page.");
            }
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            showAlertDialogError(
              error?.response?.data?.statusDetails ||
                "Failed to create Simple Pay page.",
            );
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowBackdrop(false);
            retrieveSimplePayPage();
          }
        });
    } else {
      // Updating an existing Simple Pay page
      if (!simplePayResponse || !simplePayResponse.simplePayId) {
        showAlertDialogError("No Simple Pay page selected to update.");
        setShowBackdrop(false);
        return;
      }

      const simplePayId = simplePayResponse.simplePayId;
      const url = stringFormat(sassEndpoints.simplePay.simplePay, [
        userSettings.gatewayId,
        simplePayId,
      ]);

      const patchOperations = buildPatchOperations(formValues, metadataMapping);
      setShowBackdrop(true);

      axios
        .patch(url, patchOperations)
        .then((response) => {
          if (isMounted.current) {
            if (response.status === 200) {
              showSnackBar("Success - Simple Pay Page Updated");
              loadSimplePayPages(true);
            } else {
              showAlertDialogError("Failed to update Simple Pay page.");
            }
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            showAlertDialogError(
              error?.response?.data?.statusDetails ||
                "Failed to update Simple Pay page.",
            );
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowBackdrop(false);
            retrieveSimplePayPage();
          }
        });
    }
  };

  const retrieveSimplePayPage = () => {
    if (!simplePayList[activeIndex]) return;

    setShowBackdrop(true);
    setIsLoadingSmplPayPage(true);

    const url = stringFormat(sassEndpoints.simplePay.simplePay, [
      userSettings.gatewayId,
      simplePayList[activeIndex].simplePayId,
    ]);
    axios
      .get(url)
      .then((resp) => {
        if (isMounted.current) {
          setSimplePayResponse(resp.data.data);
          setFormKey(String(simplePayList[activeIndex].simplePayId));
          methods.reset();
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setSimplePayResponse(null);
          showAlertDialogError(
            error?.response?.data?.statusDetails ||
              "Failed to load Simple Pay page.",
          );
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowBackdrop(false);
          setIsLoadingSmplPayPage(false);
        }
      });
  };

  return (
    <div className="v2 simplepay-wrapper">
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        className="action-menu"
        anchorEl={actionAnchorEl}
        open={actionOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "right",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => handleCopy(generateUrl(selectedPageForMenu.token))}
        >
          <ContentCopySharpIcon color="secondary" />
          Copy Page URL
        </MenuItem>
        <MenuItem onClick={handleView}>
          <VisibilityOutlinedIcon color="secondary" />
          View Simple Pay Page
        </MenuItem>
        {canDeleteSimplePay && (
          <MenuItem onClick={handleDelete}>
            <DeleteOutlinedIcon color="secondary" />
            Delete Simple Pay Page
          </MenuItem>
        )}
      </Menu>
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        headerContent={
          canCreateSimplePay && (
            <Grid
              container
              flexDirection={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              className="create-div"
            >
              <Grid item />
              <Grid item>
                <Button className="btn--primary" onClick={onCreate}>
                  Create New +
                </Button>
              </Grid>
            </Grid>
          )
        }
        bodyContent={
          <SearchList
            hideSearch={true}
            title={"Simple Pay Templates"}
            data={simplePayList}
            fetchData={handleFetchData}
            hasMoreData={hasMoreData}
            activeIndex={activeIndex}
            cardProps={{
              getContent: (simplePage) => (
                <div className="page-list-item">
                  <h5>{simplePage.name}</h5>
                  <IconButton
                    onClick={(e) => {
                      handleClick(e, simplePage);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              ),
              onClick: (index) => {
                setActiveIndex(index);
                if (isCreatingNew) {
                  setIsCreatingNew(false);
                }
              },
            }}
            details={
              simplePayList.length || isCreatingNew ? (
                simplePayResponse || isCreatingNew ? (
                  <>
                    <div className="simple-header-container">
                      <h2 className="simple-header-text">
                        {simplePayResponse?.name || ""}
                      </h2>
                      {!isCreatingNew && (
                        <div className="copy-link-container">
                          <StyledToolTip
                            title="Copy Link"
                            placement="left"
                            arrow
                          >
                            <IconButton
                              onClick={() => {
                                handleCopy(
                                  generateUrl(simplePayResponse.token),
                                );
                              }}
                            >
                              <ContentCopySharpIcon />
                            </IconButton>
                          </StyledToolTip>
                        </div>
                      )}
                    </div>
                    {(!isLoadingProcessors && !isLoadingSmplPayPage) ||
                    isCreatingNew ? (
                      <SimplePayForm
                        key={formKey}
                        formKey={formKey}
                        simplePayResponse={simplePayResponse}
                        onSave={onSave}
                        generateUrl={generateUrl}
                        handleCopy={handleCopy}
                        canEditSimplePay={canEditSimplePay}
                        processors={processors}
                        isCreatingNew={isCreatingNew}
                        gatewaySurchargeable={
                          gateway?.gatewaySettings
                            .find((s) => s.code === "ALLOW_SURCHARGE")
                            .value.toLowerCase() === "true"
                        }
                      />
                    ) : null}
                  </>
                ) : (
                  <Grid container justifyContent="center" alignItems="center">
                    <p>Loading</p>
                  </Grid>
                )
              ) : (
                <Grid
                  container
                  height="50%"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    marginTop: 15,
                    borderRadius: 2,
                    padding: 3,
                  }}
                >
                  <Grid
                    container
                    textAlign="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={emptyState} alt="Empty state" />
                    </Grid>
                    <Grid item sx={{ marginTop: 0 }}>
                      <Typography variant="body1">
                        <strong>No Simple Pay Templates Found</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          />
        }
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </div>
  );
};

SimplePayContainer.propTypes = {
  customerId: PropTypes.string,
};

export default SimplePayContainer;
