import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  formatDateTime,
  formatTransactionCurrency,
} from "../../../utils/stringHelpers";
import { merchantRoutes } from "../../../constants/routes";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import DetailStatusActions from "./DetailStatusActions";
import DetailCustomFields from "./DetailCustomFields";
//import DetailLineItems from "./DetailLineItems";
import DetailAddressTables from "./DetailAddressTables";
import { getTransactionFeatureDisplay } from "./transactionHelpers.js";
import { viewTransactionDetails } from "../../../services/mixpanel/merchant/reportingTransactionsEvents";

const ReportingTransactionDetail = ({
  transactionData,
  voidTransaction,
  captureTransaction,
  refundTransaction,
  vaultTransaction,
  emailTransaction,
  printTransaction,
  userSettings,
  onIncreaseAuthClick,
}) => {
  const fieldBoldness = "600";
  const addressArray = [];
  const history = useHistory();

  let billingAddress = null;
  let shippingAddress = null;
  for (const address of transactionData.addresses) {
    if (address.isShipping) shippingAddress = address;
    if (address.isBilling) billingAddress = address;
  }

  if (billingAddress) {
    addressArray.push({
      title: "Billing Address",
      data: billingAddress,
    });
  }
  if (shippingAddress) {
    addressArray.push({
      title: "Shipping Address",
      data: shippingAddress,
    });
  }

  useEffect(() => {
    viewTransactionDetails(transactionData);
  }, []);

  const getEmail = () => {
    if (billingAddress?.email) {
      return billingAddress.email;
    } else if (shippingAddress?.email) {
      return shippingAddress.email;
    } else {
      return transactionData.emailAddress;
    }
  };

  const handleReferencedTransactionOnClick = () => {
    history.push(
      `${merchantRoutes.reporting.transaction_detail}/${transactionData.referencedTransactionId}`,
    );
  };

  const getReferencedTransactionLink = () => {
    return (
      <Link href="" onClick={handleReferencedTransactionOnClick}>
        {transactionData.referencedTransactionId}
      </Link>
    );
  };

  const InvoiceLink = (invoiceId) => {
    return (
      <Link
        sx={{ display: "flex", textDecoration: "none", cursor: "pointer" }}
        onClick={() => goToInvoice(invoiceId)}
      >
        {invoiceId}
      </Link>
    );
  };

  const generalRows = [
    { label: "Id:", value: transactionData.transactionId, visible: true },
    {
      label: "Customer Id:",
      value: CustomerLink(transactionData.customer?.customerId),
      visible: Boolean(transactionData.customer?.customerId),
    },
    {
      label: "Invoice Id:",
      value: InvoiceLink(transactionData.invoiceId),
      visible: Boolean(transactionData.invoiceId),
    },
    {
      label: "Subscription Id:",
      value: SubscriptionLink(transactionData?.subscriptionId),
      visible: Boolean(transactionData?.subscriptionId),
    },
    {
      label: "Order Id:",
      value: transactionData?.orderId,
      visible: Boolean(transactionData?.orderId),
    },
    {
      label: "PO Number:",
      value: transactionData.poNumber,
      visible: Boolean(transactionData.poNumber),
    },
    { label: "Type:", value: transactionData.type, visible: true },
    { label: "Response:", value: transactionData.response, visible: true },
    {
      label: "Response Code:",
      value: transactionData.responseCode + " - " + transactionData.response,
      visible: true,
    },
    {
      label: "Referenced Transaction Id:",
      value: getReferencedTransactionLink(),
      visible: Boolean(transactionData.referencedTransactionId),
    },
    {
      label: "Payment Method:",
      value: transactionData.paymentMethod,
      visible: true,
    },
    {
      label: "Tax Exempt:",
      value: transactionData.remit.taxExempt.toString(),
      visible: true,
    },
    {
      label: "Source:",
      value: transactionData.transactionSource,
      visible: true,
    },
    {
      label: "User Name:",
      value:
        transactionData?.createdByUser?.firstName +
        " " +
        transactionData?.createdByUser?.lastName,
      visible: Boolean(transactionData?.createdByUser || false),
    },
    {
      label: "Email:",
      value: getEmail(),
      visible: Boolean(billingAddress?.email),
    },
    {
      label: "Processor Type:",
      value: transactionData.processor.type,
      visible: Boolean(transactionData.processor.type),
    },
    {
      label: "Settlement Batch Id:",
      value: transactionData.settlementId,
      visible: Boolean(transactionData.settlementId),
    },
    {
      label: "Processor Id:",
      value: transactionData.processor.processorId,
      visible: Boolean(transactionData.processor.processorId),
    },
    {
      label: "Processor Name:",
      value: transactionData.processor.name,
      visible: Boolean(transactionData.processor.name),
    },
    {
      label: "Customer Name:",
      value: transactionData.customer?.name,
      visible: Boolean(transactionData.customer?.name),
    },
    {
      label: "Created At:",
      value: formatDateTime(transactionData.createdDateTime),
      visible: true,
    },
    {
      label: "Updated At:",
      value: formatDateTime(transactionData.modifiedDateTime),
      visible: Boolean(transactionData.modifiedDateTime),
    },
    {
      label: "Settled At:",
      value: formatDateTime(transactionData.settledDateTime),
      visible: Boolean(transactionData.settledDateTime),
    },
    {
      label: "Description:",
      value: transactionData.description,
      visible: Boolean(transactionData.description),
    },
  ];

  const amountRows = [
    {
      label: "Base Amount:",
      value: formatTransactionCurrency(transactionData.remit.baseAmount),
      visible: transactionData.remit.baseAmount !== 0,
    },
    {
      label: "Tax Amount:",
      value: formatTransactionCurrency(transactionData.remit.taxAmount),
      visible: transactionData.remit.taxAmount !== 0,
    },
    {
      label: "Shipping Amount:",
      value: formatTransactionCurrency(transactionData.remit.shippingAmount),
      visible: transactionData.remit.shippingAmount !== 0,
    },
    {
      label: "Payment Adjustment:",
      value: formatTransactionCurrency(
        transactionData.remit.paymentAdjustmentValue,
      ),
      visible: transactionData.remit.paymentAdjustmentValue !== 0,
    },
    {
      label: "Surcharge Amount:",
      value: formatTransactionCurrency(transactionData.remit.surcharge),
      visible: transactionData.remit.surcharge !== 0,
    },
    {
      label: "Amount Authorized:",
      value: formatTransactionCurrency(transactionData.remit.amountAuthorized),
      visible: transactionData.remit.amountAuthorized !== 0,
    },
    {
      label: "Amount Captured:",
      value: formatTransactionCurrency(transactionData.remit.amountCaptured),
      visible: transactionData.remit.amountCaptured !== 0,
    },
    {
      label: "Amount Settled:",
      value: formatTransactionCurrency(transactionData.remit.amountSettled),
      visible: transactionData.remit.amountSettled !== 0,
    },
    {
      label: "Amount Refunded:",
      value: formatTransactionCurrency(transactionData.remit.amountRefunded),
      visible: transactionData.remit.amountRefunded !== 0,
    },
  ];

  let creditCardRows = [];
  let achRows = [];

  //Credit Card
  if (transactionData.transactionPaymentMethod.card) {
    creditCardRows = [
      {
        label: "Auth Code:",
        value: transactionData.authCode,
      },
      {
        label: "Processor Response Text:",
        value:
          transactionData.processorResponseCode +
          " - " +
          transactionData.processorResponseText,
      },
      {
        label: "CVV Response Code:",
        value: transactionData.transactionPaymentMethod.cvvResponse,
      },
      {
        label: "AVS Response Code:",
        value: transactionData.transactionPaymentMethod.avsResponse,
      },
    ];
  }

  //Ach
  if (transactionData.transactionPaymentMethod.ach) {
    achRows = [
      {
        label: "SEC Code:",
        value: transactionData.transactionPaymentMethod.ach.secCode,
      },
      {
        label: "Account Type:",
        value: transactionData.transactionPaymentMethod.ach.accountType,
      },
      {
        label: "Account Number:",
        value: transactionData.transactionPaymentMethod.ach.maskedAccount,
      },
      {
        label: "Auth Code:",
        value: transactionData.authCode,
      },
      {
        label: "Processor Type:",
        value: transactionData.processor.type,
        visible: Boolean(transactionData.processor.type),
      },
      {
        label: "Processor Id:",
        value: transactionData.processor.processorId,
      },
      {
        label: "Processor Response Text:",
        value:
          transactionData.processorResponseCode +
          " - " +
          transactionData.processorResponseText,
      },
      {
        label: "Processor Response Code:",
        value: transactionData.processorResponseCode,
      },
    ];
  }

  function CustomerLink(customerId) {
    return (
      <Link
        sx={{ display: "flex", textDecoration: "none", cursor: "pointer" }}
        to=""
        onClick={() => goToCustomer(customerId)}
        data-cy="tran-cust-id"
      >
        {customerId}
      </Link>
    );
  }

  function checkBold(value) {
    return value === "Response:";
  }

  const goToCustomer = (customerId) => {
    history.push({
      pathname: `${merchantRoutes.customer.base}/${customerId}`,
      search: "payments",
    });
  };

  const goToInvoice = (invoiceId) => {
    history.push(`${merchantRoutes.invoices.base}/${invoiceId}`);
  };

  function SubscriptionLink(subscriptionId) {
    return (
      <Link
        sx={{ display: "flex", textDecoration: "none", cursor: "pointer" }}
        to=""
        onClick={() => goToSubscription(subscriptionId)}
        data-cy="tran-cust-id"
      >
        {subscriptionId}
      </Link>
    );
  }

  const goToSubscription = (subscriptionId) => {
    history.push({
      pathname: merchantRoutes.recurring_billing.subscriptions,
      state: {
        subscriptionId: subscriptionId,
      },
    });
  };

  return (
    <>
      <Grid container sx={{ paddingTop: "30px" }}>
        {/* Status & Actions component */}
        <DetailStatusActions
          transactionData={transactionData}
          voidTransaction={voidTransaction}
          captureTransaction={captureTransaction}
          refundTransaction={refundTransaction}
          vaultTransaction={vaultTransaction}
          emailTransaction={emailTransaction}
          printTransaction={printTransaction}
          fieldBoldness={fieldBoldness}
          userSettings={userSettings}
          onIncreaseAuthClick={onIncreaseAuthClick}
        />
        <Grid item xs={12} md={7} padding={{ xs: 0, md: 1 }}>
          {/* General Table */}
          <TableContainer>
            <Table
              stickyHeader
              aria-label="general table"
              sx={{ width: "100%" }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ fontWeight: fieldBoldness }}
                    >
                      General
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generalRows
                  .filter((row) => row.visible === true)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>
                          {" "}
                          <span
                            style={{
                              fontWeight: checkBold(row?.label)
                                ? "bold"
                                : "initial",
                            }}
                          >
                            {" "}
                            {row.label}{" "}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              fontWeight: checkBold(row?.label)
                                ? "bold"
                                : "initial",
                            }}
                            data-cy={row.label}
                          >
                            {row.value}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={5} padding={{ xs: 0, md: 1 }}>
          {/* Amount Table */}
          <TableContainer>
            <Table aria-label="amount table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ fontWeight: fieldBoldness }}
                    >
                      Amount
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ fontWeight: fieldBoldness }}
                    >
                      Total Amount:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ fontWeight: fieldBoldness }}
                    >
                      {formatTransactionCurrency(transactionData.remit.amount)}
                    </Typography>
                  </TableCell>
                </TableRow>
                {amountRows
                  .filter((row) => row.visible === true)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{row.label}</TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                            fontWeight: fieldBoldness,
                          }}
                          data-cy={row.label}
                        >
                          {row.value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Credit Card Table */}
          {creditCardRows.length !== 0 && (
            <TableContainer>
              <Table stickyHeader aria-label="credit card table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ fontWeight: fieldBoldness }}
                      >
                        Credit Card
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell colSpan={2}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={10} md={8}>
                          <Chip
                            label={
                              transactionData.transactionPaymentMethod.card
                                .cardType
                            }
                            color="primary"
                            sx={{ marginRight: 1.5, marginTop: -1 }}
                          />
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            noWrap={false}
                            marginBottom={0}
                            marginTop={0}
                            padding={0}
                            sx={{
                              fontWeight: fieldBoldness,
                              display: "inline-block",
                            }}
                          >
                            {
                              transactionData.transactionPaymentMethod.card
                                .maskedCard
                            }
                          </Typography>
                        </Grid>
                        <Grid item sx={{ textAlign: "right" }} xs={2} md={4}>
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            noWrap={true}
                            sx={{ fontWeight: fieldBoldness }}
                          >
                            {
                              transactionData.transactionPaymentMethod.card
                                .expirationDate
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>

                  {creditCardRows.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{row.label}</TableCell>
                        <TableCell>{row.value}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Ach Table */}
          {achRows.length !== 0 && (
            <TableContainer>
              <Table stickyHeader aria-label="ach table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ fontWeight: fieldBoldness }}
                      >
                        ACH
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {achRows.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{row.label}</TableCell>
                        <TableCell>{row.value}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
      {/* Custom Fields */}
      {transactionData.customFieldEntries &&
        transactionData.customFieldEntries.length > 0 && (
          <DetailCustomFields
            transactionData={transactionData}
            fieldBoldness={fieldBoldness}
          />
        )}

      <Grid container>
        {/* Line Items Must be added once line items added to transaction
        {transactionData.line_items && (
          <DetailLineItems
            transactionData={transactionData}
            fieldBoldness={fieldBoldness}
          />
        )}*/}
        {/* Features Table */}
        {transactionData.transactionFeatures &&
          transactionData.transactionFeatures.length > 0 && (
            <Grid item xs={12} paddingRight={2} marginTop={2}>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ fontWeight: fieldBoldness }}
              >
                Transaction Features
              </Typography>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              {transactionData.transactionFeatures.map((feature, index) => {
                return (
                  <Chip
                    label={getTransactionFeatureDisplay(feature.code)}
                    color="primary"
                    sx={{ padding: 1, margin: 0.5 }}
                    key={index}
                  />
                );
              })}
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            </Grid>
          )}
        <DetailAddressTables
          addressArray={addressArray}
          fieldBoldness={fieldBoldness}
        />
      </Grid>
    </>
  );
};

ReportingTransactionDetail.propTypes = {
  transactionData: PropTypes.object.isRequired,
  voidTransaction: PropTypes.func.isRequired,
  captureTransaction: PropTypes.func.isRequired,
  refundTransaction: PropTypes.func.isRequired,
  vaultTransaction: PropTypes.func.isRequired,
  emailTransaction: PropTypes.func.isRequired,
  printTransaction: PropTypes.func.isRequired,
  userSettings: PropTypes.object.isRequired,
  onIncreaseAuthClick: PropTypes.func.isRequired,
};

export default ReportingTransactionDetail;
